import type { SVGProps } from "react"

export const RenewableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="53"
    height="50"
    viewBox="0 0 53 50"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.122 16.6055C15.9202 16.9596 16.0437 17.4102 16.3979 17.612C16.752 17.8138 17.2026 17.6903 17.4044 17.3361L16.122 16.6055ZM30.427 8.07909L29.7873 8.44694L30.427 8.07909ZM17.4044 17.3361L22.4609 8.46138L21.1786 7.73073L16.122 16.6055L17.4044 17.3361ZM29.7873 8.44694L34.242 16.1943L35.5215 15.4586L31.0668 7.71125L29.7873 8.44694ZM22.4609 8.46138C24.0764 5.62609 28.1607 5.61804 29.7873 8.44694L31.0668 7.71125C28.8714 3.89316 23.3589 3.90402 21.1786 7.73073L22.4609 8.46138Z" />
    <path d="M21.9128 41.9303C22.3204 41.9303 22.6508 41.5999 22.6508 41.1923C22.6508 40.7847 22.3204 40.4544 21.9128 40.4544L21.9128 41.9303ZM21.9128 40.4544L11.8274 40.4544L11.8274 41.9303L21.9128 41.9303L21.9128 40.4544ZM8.17556 34.1171L12.4969 26.6609L11.2199 25.9208L6.89861 33.377L8.17556 34.1171ZM11.8274 40.4544C8.57509 40.4544 6.54474 36.9309 8.17556 34.1171L6.89861 33.377C4.69755 37.1748 7.43786 41.9303 11.8274 41.9303L11.8274 40.4544Z" />
    <path d="M40.4025 24.6048C40.1947 24.2542 39.742 24.1384 39.3914 24.3462C39.0408 24.554 38.925 25.0067 39.1328 25.3573L40.4025 24.6048ZM39.1328 25.3573L44.3038 34.0815L45.5734 33.329L40.4025 24.6048L39.1328 25.3573ZM40.6728 40.4545L31.6392 40.4545L31.6392 41.9304L40.6728 41.9304L40.6728 40.4545ZM44.3038 34.0815C45.9714 36.8951 43.9435 40.4545 40.6728 40.4545L40.6728 41.9304C45.0872 41.9304 47.8242 37.1264 45.5734 33.329L44.3038 34.0815Z" />
    <path d="M35.133 16.3023L34.942 17.0151C35.3357 17.1206 35.7403 16.887 35.8458 16.4933L35.133 16.3023ZM36.8331 12.8089C36.9385 12.4152 36.7049 12.0105 36.3113 11.9051C35.9176 11.7996 35.5129 12.0332 35.4075 12.4269L36.8331 12.8089ZM31.6395 14.6023C31.2459 14.4968 30.8412 14.7304 30.7357 15.1241C30.6303 15.5178 30.8639 15.9224 31.2575 16.0279L31.6395 14.6023ZM35.8458 16.4933L36.8331 12.8089L35.4075 12.4269L34.4202 16.1113L35.8458 16.4933ZM35.324 15.5895L31.6395 14.6023L31.2575 16.0279L34.942 17.0151L35.324 15.5895Z" />
    <path d="M31.067 41.4103L30.5452 40.8885C30.257 41.1767 30.257 41.6439 30.5452 41.9321L31.067 41.4103ZM33.2424 44.6293C33.5306 44.9175 33.9978 44.9175 34.286 44.6293C34.5742 44.3411 34.5742 43.8739 34.286 43.5857L33.2424 44.6293ZM34.286 39.2349C34.5742 38.9467 34.5742 38.4794 34.286 38.1913C33.9978 37.9031 33.5306 37.9031 33.2424 38.1913L34.286 39.2349ZM30.5452 41.9321L33.2424 44.6293L34.286 43.5857L31.5888 40.8885L30.5452 41.9321ZM31.5888 41.9321L34.286 39.2349L33.2424 38.1913L30.5452 40.8885L31.5888 41.9321Z" />
    <path d="M12.3027 25.7442L13.0263 25.5995C12.9464 25.1998 12.5576 24.9406 12.158 25.0206L12.3027 25.7442ZM8.41761 25.7685C8.01797 25.8485 7.75878 26.2372 7.8387 26.6369C7.91862 27.0365 8.30738 27.2957 8.70703 27.2158L8.41761 25.7685ZM12.3271 29.6293C12.407 30.0289 12.7957 30.2881 13.1954 30.2082C13.595 30.1283 13.8542 29.7395 13.7743 29.3399L12.3271 29.6293ZM12.158 25.0206L8.41761 25.7685L8.70703 27.2158L12.4474 26.4678L12.158 25.0206ZM11.5791 25.8889L12.3271 29.6293L13.7743 29.3399L13.0263 25.5995L11.5791 25.8889Z" />
    <g
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4759"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.7069 24.7749L21.5117 35.5052" />
      <path d="M22.7508 33.3593C17.854 28.6245 20.8557 21.7732 30.598 19.7675C33.7321 29.2074 29.2997 35.2326 22.7508 33.3593Z" />
    </g>
  </svg>
)
export default RenewableIcon
